.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* FAQ Classes */
.FAQItem {
  padding-top: 15px;
}

.Q {
  font-size: 24px;
}

.A {
  font-size: 16px;
  font-style:italic;
  padding-left: 20px;
  opacity: 0.85;
}

/* Amplify stuff: https://docs.amplify.aws/ui/customization/theming/q/framework/react */
:root {
  --amplify-primary-color: #fb5430;
  --amplify-primary-tint: #fb5430;
  --amplify-primary-shade: #fb5430;
}

amplify-authenticator {
  --container-height: 50vh
}